import { guid } from "@inwink/utils/methods/guid";
import type { Entities } from "@inwink/entities/entities";
import type { States } from "@@services/services";
import type { IWithToastProviderProps } from './withToastProvider';

export function addToast(
    arg: IAddToastArgument,
    optionsParam: IToastOptions,
    type: IToastType,
    defaultOptions: IGlobalToastOptions,
    store: any,
    location: States.ILocation,
    history: any,
    isRootWebsite: boolean,
    urlservice: States.IAppUrlContext,
    i18nHelper: Entities.i18nHelper,
    props: IWithToastProviderProps,
    remove: (id: string) => void
) : Promise<IToast> {
    const state: States.IAppState = store.getState();
    if (state?.event?.eventid) {
        return import("@@event/components/toast").then((toast) => {
            return toast.addToast(
                arg,
                optionsParam,
                type,
                defaultOptions,
                store,
                location,
                history,
                isRootWebsite,
                urlservice,
                i18nHelper,
                props,
                remove
            );
        });
    }

    const id = guid();

    const result: IToast = {
        id,
        content: null,
        title: arg.title,
        message: arg.message,
        type,
        toast: arg,
        options: Object.assign({}, defaultOptions, optionsParam)
    };

    return Promise.resolve(result);
}
