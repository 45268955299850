import type { Entities } from '@inwink/entities/entities';
import type { IPopoverManager } from '@inwink/modals/popovermgr';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { States } from '@@services/services';
import { AppHeaderContent } from './appheadercontent';
import type { IAppHeaderProps } from './appheader.props';

interface IAppHeaderWrapperProps extends IAppHeaderProps {
    popovermgr: IPopoverManager;
    i18nHelper?: Entities.i18nHelper;
    history?: any;
    page?: States.ICurrentPageState;
    children?: React.ReactNode;
}

@withI18nHelper()
export class AppHeaderWrapperComponent extends React.Component<IAppHeaderWrapperProps, any> {
    constructor(props: IAppHeaderWrapperProps) {
        super(props);
        this.state = { };
    }

    render() {
        const classNames = ["app-header-content"];

        if (this.props.user && this.props.user.currentUser) {
            if (this.props.user.currentUser.detail?.photo && (this.props.user.currentUser.detail.photo as any).url) {
                classNames.push("with-user-picture");
            }
        }

        if (!this.props.hasBurgerBtn) {
            classNames.push("withsmallmargin");
        }

        let backbtn;

        return <div className={classNames.join(" ")}>
            {backbtn}
            <AppHeaderContent {...this.props} />
            {this.props.children ? React.cloneElement(this.props.children as any, this.props) : null}
        </div>;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        user: state.user,
        pages: state.pages,
        page: state.pages.currentPage
    };
}

export const AppHeaderWrapper: new (props: IAppHeaderWrapperProps)
=> React.Component<IAppHeaderWrapperProps, any> = connect(
    mapStateToProps
)(withRouter(AppHeaderWrapperComponent as any)) as any;
