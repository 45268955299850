import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as i18nActions } from '@@services/i18nservice';
import { States } from '@@services/services';

interface IAppHeaderLanguageProps {
    history?: any;
    location?: any;
    match?: any;
    i18n?: States.i18nState;
    i18nActions?: typeof i18nActions;
}

function AppHeaderLanguageComponent(props: IAppHeaderLanguageProps) {
    if (props.i18n && props.i18n.supported && props.i18n.supported.length > 1) {
        const lngs = props.i18n.supported.map((k) => {
            const click = (arg: React.MouseEvent<any>) => {
                arg.preventDefault();
                arg.stopPropagation();

                props.i18nActions.setCurrentLanguage(props.location, props.history, props.match, props.match.params, k);
            };
            const className = (props.i18n.currentLanguageCode === k ? "bloc-accent" : null);
            return <div
                key={k}
                className={"lng clickable" + (props.i18n.currentLanguageCode === k ? " current" : "")}
                onClick={click}
            ><span className={className}>{k}</span></div>;
        });

        return <div className="menu-languages">
            {lngs}
        </div>;
    }

    return null;
}

function mapStateToProps(state: States.IAppState) {
    return {
        i18n: state.i18n
    };
}
function mapDispatchToProps(dispatch) {
    return {
        i18nActions: bindActionCreators(i18nActions, dispatch)
    };
}

export const AppHeaderLanguage : new (any)
=> React.Component<IAppHeaderLanguageProps, any> = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AppHeaderLanguageComponent as any) as any
) as any;
