import * as React from 'react';
import { connect } from 'react-redux';
import type { Entities } from '@inwink/entities/entities';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import type { States } from '../services/services';
import { wrapReduxStore, IInwinkStore } from '../store';

export interface ICookieBarAction {
    label?: VisualTheme.IAppLabel;
    action?: "accept" | "deny";
    link?: VisualTheme.IAppLink;
}

export interface ICookieBarConfiguration {
    enabled?: boolean;
    titles?: VisualTheme.IAppLabel;
    labels?: VisualTheme.IAppLabel;
    style?: any;
    overlayStyle?: any;
    theme?: string;
    position?: string;
    overlay?: boolean;
    actions?: ICookieBarAction[];
}

export interface ICookieTrackingProps {
    i18n : States.i18nState;
    config: Entities.IEventDetailConfigurationCompanionCookiesBanner;
    tracking: Entities.IEventDetailConfigurationTracking;
    trackingState?: States.ITrackingState;
    consentTracking?: States.IConsentTracking;
    cookieid: string;
    eventId: string;
    websiteId: string;
    store?: IInwinkStore;
    children: (cookiebar: any, className: string) => React.ReactNode
}

class CookieTrackingComponent extends React.PureComponent<ICookieTrackingProps, any> {
    render() {
        if (this.props.consentTracking && this.props.consentTracking.component) {
            return React.createElement(this.props.consentTracking.component, this.props);
        }

        return this.props.children(null, '');
    }
}

export const CookieTracking: new (any) => React.Component<ICookieTrackingProps, any> = connect(
    (state: States.IAppState) => {
        return {
            trackingState: state.tracking,
            consentTracking: state.tracking?.consentTracking
        };
    }
)(wrapReduxStore(CookieTrackingComponent) as any) as any;
