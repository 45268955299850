import { textColorFor } from '@inwink/utils/methods/textcolorfor';
import { WithPopoverManager } from '@inwink/modals/popovermgr';
import * as React from 'react';
import { IAppHeaderProps } from './appheader.props';
import { AppHeaderWrapper } from './appheader.wrapper';

import './appheader.less';

function AppHeaderControl(props: IAppHeaderProps) {
    let style = {} as any;
    const visualtheme = props.visualConfiguration;
    if (visualtheme && visualtheme.global && visualtheme.global.mainColor) {
        style.backgroundColor = visualtheme.global.mainColor;
        style.color = textColorFor(visualtheme.global.mainColor);
    }

    if (visualtheme?.appHeader?.style) {
        style = visualtheme.appHeader.style;
    }

    let customcontent;
    if (props.customContent) {
        customcontent = React.createElement(props.customContent, props);
    }

    return (<WithPopoverManager>
        {(popovermgr) => <div className={"app-header " + (props.className ? props.className : "")} style={style}>
            <AppHeaderWrapper {...props} popovermgr={popovermgr} />
            {customcontent}
        </div>}
    </WithPopoverManager>);
}

// eslint-disable-next-line import/no-mutable-exports
export let AppHeader = AppHeaderControl;

export function hookAppHeader(callback) {
    AppHeader = callback(AppHeaderControl);
}
