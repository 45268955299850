import { logging } from '@inwink/logging';
import { getTargetInfoFromKey, ITargetInfo } from '../api/front/mapping';
import { setTargetInfoProvider } from '../services';
const guidregex = "^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$";
export const isValidGuid = RegExp(guidregex);

interface IEventInfoCache extends ITargetInfo {
    date: Date;
}

let eventIDs: Record<string, IEventInfoCache> = {};

export function clearEventIds() {
    eventIDs = {};
}

function keyFor(eventKey: string) {
    return inwink.config.env + "-eventinfo-" + eventKey;
}

export function setEventInfoFor(eventKey: string, info: ITargetInfo) {
    const item = Object.assign({}, info, {
        date: new Date()
    });
    eventIDs[eventKey] = item;

    if ((global as any).localStorage) {
        try {
            (global as any).localStorage.setItem(keyFor(eventKey), JSON.stringify(item));
        } catch (e) {
            logging.error("error saving eventinfo to localstorage", e);
        }
    }
}

export function getCachedEventInfoFor(eventKey) : IEventInfoCache {
    if ((global as any).localStorage) {
        try {
            const strItem = (global as any).localStorage.getItem(keyFor(eventKey));
            if (strItem) {
                const item: IEventInfoCache = JSON.parse(strItem);
                item.date = new Date(item.date);
                eventIDs[eventKey] = item;
                return item;
            }
        } catch (e) {
            logging.error("error reading eventinfo from localstorage", e);
        }
    }

    return eventIDs[eventKey];
}

export function initEventInfoProvider() {
    setTargetInfoProvider((eventRequests, eventKey) => {
        const cached = getCachedEventInfoFor(eventKey);

        if (cached) {
            const diff = (new Date() as any) - (cached.date as any);
            if (diff < 3600 * 2 * 1000) {
                return Promise.resolve(cached);
            }
        }

        let targetKey = eventKey;
        if (InWinkPreview) {
            const idx = targetKey.lastIndexOf("_");
            if (idx >= 0) {
                const tmp = targetKey.substring(idx + 1);
                if (isValidGuid.test(tmp)) {
                    targetKey = tmp;
                }
            }
        }

        return getTargetInfoFromKey(eventRequests, targetKey).then((eventinfo) => {
            setEventInfoFor(eventKey, eventinfo);
            return eventinfo;
        });
    });
}
