import * as React from 'react';
import type { IAppMenuProps } from "@@components/appmenu/appmenu.props";
import { AppMenuUserStatus } from './userstatus';

export interface IAppMenuEventProps extends IAppMenuProps {
}
export interface IAppMenuEventState {
}

// eslint-disable-next-line react/prefer-stateless-function
class AppMenuLanguagesControl extends React.Component<IAppMenuEventProps, IAppMenuEventState> {
    render() {
        return <AppMenuUserStatus {...this.props} customContent={null} className="" />;
    }
}

export default AppMenuLanguagesControl;
