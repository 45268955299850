import * as React from 'react';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { onUserMessageLoaded, userMessageModule } from '../routes/appmodules';
import { States } from '../services/services';

import './appusermessage.less';

interface IUserMessageProps {
    usermessage?: States.IAppUserMessageState;
}

@connectwith((state: States.IAppState) => {
    return {
        usermessage: state.usermessage
    };
})
export class AppUserMessage extends React.PureComponent<IUserMessageProps, any> {
    InWinkMessageComponent: any;

    unsubscribe: () => void;

    constructor(props) {
        super(props);

        if (!__SERVERSIDE__) {
            this.unsubscribe = onUserMessageLoaded(() => this.loadComponent());
        }
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    componentDidUpdate(prevprops: IUserMessageProps) {
        if (this.props.usermessage !== prevprops.usermessage && !this.InWinkMessageComponent) {
            this.loadComponent();
        }
    }

    loadComponent() {
        return userMessageModule().then((mod) => {
            this.InWinkMessageComponent = mod.AppUserMessage;
            this.setState({});
        });
    }

    render() {
        if (!this.InWinkMessageComponent) {
            return null;
        }
        return React.createElement(this.InWinkMessageComponent);
    }
}
