import * as React from 'react';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import type { Entities } from '@inwink/entities/entities';
import { States } from '@@services/services';
import type { IAppMenuProps } from './appmenu.props';

export function loadMenu() {
    return import("./appmenu");
}

const AppMenuContent = React.lazy(loadMenu);

export interface IAppMenuRealProps extends IAppMenuProps {
    onHide: (any) => void;
    menuTemplate: Entities.IContentTemplate;
    visualConfiguration: Entities.IVisualConfigurationTemplate;
    firstPass?: boolean;
    loadMenu?: boolean;
    datacontext: Entities.IPageDataContext;
}
interface IAppMenuState {
    initialized: boolean;
    loaded: boolean;
}

@connectwith((state: States.IAppState) => {
    return {
        firstPass: state.ssr?.firstPass,
        showMenu: state.appMetaData.showMenu,
        loadMenu: state.appMetaData.loadMenu
    };
})
export class AppMenu extends React.Component<IAppMenuRealProps, IAppMenuState> {
    constructor(props) {
        super(props);
        this.state = {
            initialized: false,
            loaded: false
        };
    }

    componentDidUpdate() {
        if ((this.props.showMenu || this.props.loadMenu) && !this.state.initialized) {
            this.setState({
                initialized: true
            });
        }
    }

    menuReady = () => {
        this.setState({ loaded: true });
    };

    render() {
        if (this.state.initialized) {
            return <React.Suspense fallback={<React.Fragment />}>
                <AppMenuContent
                    {...this.props}
                    showMenu={this.state.loaded && this.props.showMenu}
                    onReady={this.menuReady}
                >{this.props.children}</AppMenuContent>
            </React.Suspense>;
        }

        return null;
    }
}
