import type { IRequestManager } from '../../services/apiaccessprovider.definition';

export function eventMailTracking(requestManager: IRequestManager, eventid: string, mailid: string) {
    return requestManager.get(`t/event/${eventid}/${mailid}?trackOnly=true`);
}

export function tenantMailTracking(requestManager: IRequestManager, tenantId: string, mailid: string) {
    return requestManager.get(`t/tenant/${tenantId}/${mailid}?trackOnly=true`);
}

export function communityMailTracking(requestManager: IRequestManager, communityId: string, mailid: string) {
    return requestManager.get(`t/community/${communityId}/${mailid}?trackOnly=true`);
}
