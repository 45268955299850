import type { IRequestManager } from '../../services/apiaccessprovider.definition';

export interface ITargetInfo {
    eventId:string;
    customerId: string;
    authTenantId: string;
    communityId?: string;
    licenseExpiresAt?: string;
    licenseExpiresAtWithTolerance?: string;
    computedUrl?: string;
}

export function getTargetInfoFromKey(requestmgr: IRequestManager, eventkey)
    : Promise<ITargetInfo> {
    return requestmgr.getJson("event/mapping?eventkey=" + eventkey);
}
