import * as React from 'react';
import { ThemedModalContext, IThemedModalContext } from '@inwink/modals-theme/themedmodalcontext';
import type { IThemedModalPartProps } from '@inwink/modals-theme/themedmodal';
import type { Entities } from '@inwink/entities/entities';

import './modalthemecontext.less';

export interface IModalThemeContextProps {
    visualConfiguration: Entities.IVisualConfigurationTemplate;
    prefix: string;
    children?: React.ReactNode;
}

interface IModalThemeContextState {
    themeContext: IThemedModalContext
}

export class ModalThemeContext extends React.Component<IModalThemeContextProps, IModalThemeContextState> {
    constructor(props) {
        super(props);

        const vconf = this.props.visualConfiguration;

        this.state = {
            themeContext: {
                name: "",
                theme: {
                    modalClassName: this.props.prefix,
                    popoverClassName: this.props.prefix,
                    modalHeaderWrapper: (headerprops: IThemedModalPartProps) => {
                        return <div className="themeheader">
                            <div className="themeheader-title">
                                {(headerprops as any).children}
                            </div>
                            <button
                                type="button"
                                className="btnclose"
                                onClick={(arg) => {
                                    arg.preventDefault();
                                    if (headerprops.dismissmgr) {
                                        headerprops.dismissmgr.close();
                                    }
                                }}
                            >
                                <i className="inwink-dialog-cancel" />
                            </button>
                        </div>;
                    },
                    modalWrapper: (modalprops: IThemedModalPartProps) => <div
                        className={"bloctheme" + (vconf.global.modalTheme ? ` bloctheme-${vconf.global.modalTheme}` : "")}
                    >{modalprops.children}</div>,
                    popoverWrapper: (modalprops: IThemedModalPartProps) => <div
                        className={"bloctheme" + (vconf.global.popoverTheme ? " bloctheme-" + vconf.global.popoverTheme : "")}
                    >{modalprops.children}</div>,
                }
            }
        };
    }

    render() {
        return <ThemedModalContext.Provider value={this.state.themeContext}>
            {this.props.children}
        </ThemedModalContext.Provider>;
    }
}
