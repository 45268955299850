import * as React from 'react';
import { Link } from 'react-router-dom';
import { BlocMenuItems } from '../dynamicpage/blocs/bloc.menuitems';
import { withUrlService } from '../urlstatecontext';
import { AppHeaderLanguage } from './language';
import type { IAppHeaderContentProps } from "./appheader.props";

@withUrlService()
export class AppHeaderContent extends React.Component<IAppHeaderContentProps, any> {
    render() {
        const urlservice = this.props.urlservice;
        const page = this.props.pages ? this.props.pages.currentPage : undefined;
        let title; let logo; let itemsCtrl; let companylogo; let
            hideLanguages = false;
        const titleClass = ["pagetitle"];
        if (this.props.user && this.props.user.currentUser) {
            titleClass.push("withuser");
        }

        if (page) {
            title = <div className="pagetitle-content">{page.title}</div>;
        }

        const visualtheme = (this.props.visualConfiguration as any) || {};
        const headerLogoLabel = this.props.title || '';

        if (visualtheme.appHeader && visualtheme.appHeader.logo) {
            logo = <Link to={urlservice.pageUrl("")} className="header-logo mainlogo" aria-label={headerLogoLabel}>
                <img src={visualtheme.appHeader.logo} alt={headerLogoLabel} />
            </Link>;
            titleClass.push("withlogo");
        }

        if (visualtheme.appHeader && visualtheme.appHeader.companylogo && visualtheme.appHeader.companylogo.url) {
            companylogo = <div className="company-logo">
                <a href={visualtheme.appHeader.companylogo.url} className="header-logo seclogo" target="blank">
                    <img alt="" src={visualtheme.appHeader.companylogo.logo} />
                </a>
            </div>;
            if (titleClass.indexOf("withlogo") === -1) titleClass.push("withlogo");
        }

        if (this.props.headerTemplate?.config?.blocs?.length) {
            const items = this.props.headerTemplate.config.blocs[0].content[0];
            if (items) {
                let align = items.properties.align;
                hideLanguages = items.properties && items.properties.hideLanguageSelector;
                const headerSettings = this.props.headerSettings;
                if (headerSettings?.alignItemsLeft) {
                    align = "left";
                }
                itemsCtrl = <BlocMenuItems
                    {...this.props as any}
                    bloctemplate={this.props.headerTemplate.config.blocs[0]}
                    urlservice={urlservice}
                    template={items}
                    isAppHeader={true}
                    showSubMenuIcon={this.props.headerSettings?.enableSubMenuArrow}
                    theme={visualtheme.appHeader}
                    datacontext={this.props.datacontext}
                    page={null}
                    update={null}
                    blocState={null}
                    pageActions={null}
                    menuId="appheadermenu"
                />;
                titleClass.push("withheaderitems");

                if (items.properties.align) {
                    titleClass.push("align-" + align);
                }
            }
        }

        return <div className={titleClass.join(" ")}>
            {logo}
            {companylogo}
            <div className="title">
                {title}
            </div>
            <div className="menu" id="appheadermenu">
                {itemsCtrl}
            </div>
            {hideLanguages ? null : <AppHeaderLanguage />}
        </div>;
    }
}
