import * as React from 'react';
import { withRouter } from 'react-router-dom';
import type { IAppMenuEventProps } from "./index";

const Content = React.lazy(() => import('./userstatus.content'));
interface IAppMenuUserStatusControlProps extends IAppMenuEventProps {
}
// eslint-disable-next-line react/prefer-stateless-function
class AppMenuUserStatusControl extends React.Component<IAppMenuUserStatusControlProps, any> {
    render() {
        if (__SERVERSIDE__) {
            return null;
        }

        return <React.Suspense fallback={<Empty />}>
            <Content {...this.props} />
        </React.Suspense>;
    }
}

function Empty() {
    return <></>;
}

// eslint-disable-next-line import/no-mutable-exports
const _AppMenuUserStatus: React.ComponentClass<IAppMenuUserStatusControlProps> = withRouter(
    AppMenuUserStatusControl as any
) as any;

// eslint-disable-next-line import/no-mutable-exports
export let AppMenuUserStatus = _AppMenuUserStatus;
export function hookAppMenuUser(callback) {
    AppMenuUserStatus = callback(_AppMenuUserStatus);
}

export default AppMenuUserStatus;
